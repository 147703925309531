import styled from "styled-components"
import { lighten } from "polished"

export const Badge = styled.a`
  text-transform: uppercase;
  background-color: ${props => `${lighten(0.3, props.theme.color.primary)}`};
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: 700;
  font-size: 0.6em;
  display: inline-flex;
  align-items: center;
  padding: 0.25em 0.5em;
  border-radius: 4px;
  margin-left: 0.5em;
  transform: translateY(-1px);

  &:hover {
    background-color: ${props => `${lighten(0.2, props.theme.color.primary)}`};
  }

  & > span {
    width: 1.2em;
    height: 1.2em;
    margin-left: 0.1em;
  }

  svg {
    transform: translateY(-0.1em);
  }
`
