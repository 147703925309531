import Collapse, { CollapseLink } from "components/Collapse"
import React, { ReactNode, useState } from "react"

type Props = {
  list: (string | ReactNode)[]
  maxItems: number
  white?: boolean
  customButtonShowText?: string
}

const ListItems = ({ list }: { list: (string | ReactNode)[] }) => (
  <>{list.map((item, index) => !!item && <li key={index}>{item}</li>)}</>
)

const CollapsibleList = ({
  white,
  list,
  maxItems,
  customButtonShowText,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleCollapseToggle = (e?: React.MouseEvent<Element, MouseEvent>) => {
    if (e) e.preventDefault()
    setIsCollapsed(!isCollapsed)
  }

  return (
    <>
      {list.length <= maxItems ? (
        <ol>
          <ListItems list={list} />
        </ol>
      ) : (
        <>
          <div style={{ marginBottom: "0.25em" }}>
            <ol>
              <ListItems list={list.slice(0, maxItems)} />
            </ol>
            <Collapse isCollapsed={isCollapsed}>
              <ol style={{ counterReset: `li ${maxItems}` }}>
                <ListItems list={list.slice(maxItems, list.length)} />
              </ol>
            </Collapse>
          </div>
          <CollapseLink
            isCollapsed={isCollapsed}
            white={white}
            onClick={handleCollapseToggle}
            customButtonShowText={customButtonShowText}
          />
        </>
      )}
    </>
  )
}

export default CollapsibleList
