import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Entry from "components/Entry"
import Link from "components/Link"
import {
  albumsOutline,
  bookOutline,
  checkboxOutline,
  chevronBack,
  downloadOutline,
  helpOutline,
  megaphoneOutline,
  openOutline,
} from "ionicons/icons"
import Heading from "components/Heading"
import AudioPlayer from "components/AudioPlayer"
import SectionTitle from "components/SectionTitle"
import WIcon from "components/WIcon"
import kkkIcon from "components/CustomIcons/kkk"
import CollapsibleList from "components/CollapsibleList"
import config from "config/config"
import Icon from "components/Icon"
import Row from "components/Row"
import IconButton from "components/IconButton"
import { ViewContextConsumer } from "context/viewContext"
import HomeLayout from "layouts/HomeLayout"
import HomeContainer from "components/HomeContainer"
import loadable from "@loadable/component"
import { Badge } from "components/Badge/Badge"

const Presenter = loadable(() => import("components/Presenter"))

const useSampleMeetingQuery = () => {
  const staticData = useStaticQuery(graphql`
    query {
      strapi {
        homepage {
          sample_meeting {
            number
            title
            date
            slug
            conference {
              title
              file {
                url
              }
            }
            catechism {
              from
              to
            }
            questions {
              question
            }
            tasks {
              task
            }
            books {
              author
              title
              url
            }
            presentation {
              file {
                url
              }
            }
            preach {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  return { staticData }
}

const PrzykladoweSpotkanie = () => {
  const { staticData } = useSampleMeetingQuery()
  const {
    date,
    number,
    title,
    conference,
    catechism,
    questions,
    tasks,
    books,
    presentation,
    preach,
  } = staticData.strapi.homepage.sample_meeting
  return (
    <HomeLayout>
      <ViewContextConsumer>
        {viewContext => (
          <HomeContainer>
            {(viewContext?.media.md ||
              viewContext?.media.lg ||
              viewContext?.media.xl) && (
              <Entry.Header>
                <Link icon={chevronBack} to={"/"}>
                  Powrót
                </Link>
                <Heading>Przykładowe spotkanie #{number}</Heading>
              </Entry.Header>
            )}
            {conference && conference.file && (
              <Entry.Section>
                <div style={{ marginBottom: "24px" }}>
                  <AudioPlayer
                    title={conference.title}
                    src={conference.file.url}
                    downloadButton
                  />
                </div>
              </Entry.Section>
            )}
            {catechism && catechism.from && catechism.to && (
              <Entry.Section>
                <div style={{ marginBottom: "24px" }}>
                  <SectionTitle withLine>
                    <WIcon icon={kkkIcon} />Z katechizmu
                  </SectionTitle>
                  <p>
                    {number}. {title}
                    <br />
                    pkt. {catechism.from} – {catechism.to}
                  </p>
                </div>
              </Entry.Section>
            )}
            {questions && !!questions.length && (
              <Entry.Section>
                <SectionTitle withLine>
                  <WIcon icon={helpOutline} />
                  Pytania
                </SectionTitle>
                <div style={{ marginBottom: "24px" }}>
                  <CollapsibleList
                    list={questions.map(item => item.question)}
                    maxItems={4}
                  />
                </div>
              </Entry.Section>
            )}
            {tasks && !!tasks.length && (
              <Entry.Section>
                <SectionTitle withLine>
                  <WIcon icon={checkboxOutline} />
                  Zadania
                </SectionTitle>
                <div style={{ marginBottom: "24px" }}>
                  <CollapsibleList
                    list={tasks.map(item => item.task)}
                    maxItems={4}
                  />
                </div>
              </Entry.Section>
            )}
            {books && !!books.length && (
              <Entry.Section>
                <SectionTitle withLine>
                  <WIcon icon={bookOutline} />
                  Lektury
                </SectionTitle>
                <div style={{ marginBottom: "24px" }}>
                  <CollapsibleList
                    list={books.map(
                      item =>
                        (item.author || item.title) && (
                          <>
                            {!!item.author && <>{item.author}, </>}
                            <i>{item.title}</i>
                            {!!item.url && !!item.url.trim().length && (
                              <Badge href={item.url.trim()} target={"_blank"}>
                                URL <Icon icon={openOutline} />
                              </Badge>
                            )}
                          </>
                        )
                    )}
                    maxItems={4}
                  />
                </div>
              </Entry.Section>
            )}
            {presentation && presentation.file && (
              <Entry.Section>
                <SectionTitle withLine>
                  <Row
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    style={{ flexGrow: 1 }}
                  >
                    <Row alignItems={"center"}>
                      <WIcon icon={albumsOutline} />
                      Prezentacja
                    </Row>
                    <IconButton
                      href={config.uploadPrefix + presentation.file.url}
                    >
                      <Icon icon={downloadOutline} />
                    </IconButton>
                  </Row>
                </SectionTitle>
                <div style={{ margin: "24px 0" }}>
                  <Presenter src={presentation.file.url} />
                </div>
              </Entry.Section>
            )}
            {preach && preach.file && (
              <Entry.Section>
                <SectionTitle withLine>
                  <WIcon icon={megaphoneOutline} />
                  Kazanie
                </SectionTitle>
                <div style={{ margin: "24px 0" }}>
                  <AudioPlayer
                    title={preach.title}
                    src={preach.file.url}
                    downloadButton
                  />
                </div>
              </Entry.Section>
            )}
          </HomeContainer>
        )}
      </ViewContextConsumer>
    </HomeLayout>
  )
}

export default PrzykladoweSpotkanie
